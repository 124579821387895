import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  { text: "Тип", defaultWidth: 100, value: fields.EVENT_TYPE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Дата", defaultWidth: 120, value: fields.EVENT_DATE, isSorted: true, isHandle: true, id: uuid() },
  {
    text: "Источник",
    defaultWidth: 200,
    value: fields.EVENT_SOURCE,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    slotName: "source",
  },
  {
    text: "Категория",
    defaultWidth: 150,
    value: fields.EVENT_CATEGORY,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Описание",
    value: fields.EVENT_DESCRIPTION,
    defaultWidth: 250,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Объект",
    value: fields.EVENT_OBJ,
    defaultWidth: 200,
    isSorted: false,
    isHandle: true,
    isLink: true,
    id: uuid(),
    slotName: "eventObject",
  },
  {
    text: "Пользователь",
    value: fields.EVENT_USER,
    isSorted: false,
    defaultWidth: 142,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Код ошибки",
    value: fields.EVENT_ERROR_CODE,
    isSorted: false,
    defaultWidth: 150,
    isHandle: true,
    id: uuid(),
  },
];

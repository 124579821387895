var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-view__base-wrap"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isTableLoading && !_vm.isShowModal)?_c('TmpLogoOverlay',{staticClass:"fixed"}):_vm._e()],1),_c('div',{staticClass:"table-view__toggle-navigation-view",attrs:{"data-scroll-start-marker":""}}),_c('v-container',{staticClass:"table-view__breadcrumbs-wrap pb-0"},[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}})],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center justify-space-between"},[(_vm.isTableLoading)?_c('TmpBlock',{attrs:{"height":"32"}}):_c('h1',{staticClass:"table-view__title"},[_vm._v(_vm._s(_vm.viewTitle))])],1)])],1)],1),_c('ScrollPanel',[_c('v-row',[_c('v-col',[_c('Filters',{key:_vm.filtersLoaded,attrs:{"sectionName":_vm.sectionName,"value":_vm.isOpenFilters},on:{"change":_vm.setIsOpenFilters}})],1)],1),(_vm.isTableLoading)?_c('TmpBlock',{staticClass:"mt-9 mb-3",attrs:{"height":"20"}}):_c('TableSettings',{staticClass:"mt-6",attrs:{"loaded-count":_vm.data.length,"total-count":_vm.totalLength}},[[(_vm.isShowExport)?_c('ExportButton',{attrs:{"is-select-all":true},on:{"getExportData":_vm.getExportData}}):_vm._e(),_c('span',{staticClass:"ml-4"},[_c('ToggleTableCellsContainer')],1)]],2),(_vm.isTableLoading)?_c('TmpBlock',{attrs:{"height":"48"}}):_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('TableHeader',{staticClass:"table-header_fixed",attrs:{"tableHeaders":_vm.headers,"activeCells":_vm.cells},on:{"setCells":_vm.setCells},scopedSlots:_vm._u([{key:"sortPanel",fn:function(ref){
var id = ref.id;
return [_c('SortPanelContainer',{attrs:{"id":id}})]}}]),model:{value:(_vm.isSelectAllElement),callback:function ($$v) {_vm.isSelectAllElement=$$v},expression:"isSelectAllElement"}})],1)],1)],1),_c('div',{class:['scroll-panel__shadow', { active: _vm.isOpenFilters }]}),_c('v-container',{staticClass:"table-view"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 animation-container"},[_c('Table',{attrs:{"tableHeaders":_vm.tableHeaders,"viewUniqKey":_vm.viewUniqKey,"sectionName":_vm.sectionName,"convertItemCb":_vm.convertApiItemToUi,"refTableName":"table"},on:{"openModal":_vm.openModal},scopedSlots:_vm._u([{key:"eventObject",fn:function(ref){
var event = ref.event;
var header = ref.header;
return [_c('IdCell',{attrs:{"value":event[_vm.fields.EVENT_OBJ],"is-link":header.isLink && _vm.isAuthorityExist(event)},on:{"clickContent":function($event){return _vm.clickItem(header, event)}}})]}},{key:"source",fn:function(ref){
var event = ref.event;
var header = ref.header;
return [_c('div',[_vm._v(_vm._s(_vm.sourceTitles[event[header.value]] || ""))])]}}])})],1)],1),(_vm.filtersLoaded)?_c('InfiniteScroll',{attrs:{"get-event-list":_vm.getEventList,"infinite-id":_vm.infiniteId},on:{"getEventListFinish":_vm.getEventListFinish}}):_vm._e()],1),(_vm.isShowModal)?_c('EventLogInfoModal',{on:{"close":function($event){return _vm.toggleModal(false)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }